// DateStrap.js
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faLink, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';

import { format } from 'date-fns';

const DateStrap = ({ selectedDate, handleAbout, menuVisible, smallDateStrap, toggleMenu, isMobileView, 
                  showNew, toggleCalendar, calendarVisible, handleCopyLink, handleFeedback, handleGenome }) => {
    return !smallDateStrap ? (
      <>
        <div className="date-container">
          {/*
          <img src="https://tvlasset.b-cdn.net/UKArchiveTV-transparent.png" height="30" width="150" />
          */}
        
          {!isMobileView && (
            <>
              <div className="links-container-row">
              <span
                className="feedback-link"
                title="Find out more about the site"
                onClick={handleAbout}
              >
                About
              </span>
              <span
                className="feedback-link"
                title="Leave some feedback"
                onClick={handleFeedback}
              >
                Feedback
              </span>
              </div>
            </>
          )}
  
        {showNew && (
          <div className="fade-text">
            <span>&lt;&lt; NEW</span>
          </div>
        )}
  
        {isMobileView ? (
          <div className="date-strap">
            <span>
              {format(new Date(selectedDate), 'EEEE MMM d, yyyy')}
            </span>
          </div>
        ) : (
          <div className="date-strap">
            <span>
              {format(new Date(selectedDate), 'EEEE')}
              <br />
              {format(new Date(selectedDate), 'MMMM d, yyyy')}
            </span>
          </div>
        )}
  
        <div className="links-container-row">
          <span className="icon-button" onClick={handleCopyLink} title="Copy link to clipboard">
            <FontAwesomeIcon className="copy-link" icon={faLink} />
          </span>
          <span style={{ cursor: 'default' }}>
            &nbsp;
          </span>
          <span className="icon-button" title="Go to this day in Genome" onClick={handleGenome}>
            <img src="rt.png" />
          </span>
          <span className="icon-button" onClick={toggleCalendar} title="Open a calendar to navigate">
            <FontAwesomeIcon
              className={`cal-link ${calendarVisible ? 'active' : ''}`}
              icon={faCalendarAlt}
            />
          </span>
        </div>
        
        </div>
      </>
    ) : (
      <div className="small-date-strap">
      <button className="show-menu-button" onClick={toggleMenu}>
      ☰
      </button>
        <span>
          {format(new Date(selectedDate), 'EEE MMM d, yyyy')}
        </span>
        <div className="copy-container" onClick={toggleCalendar}>
          <FontAwesomeIcon
            className={`cal-link ${calendarVisible ? 'active' : ''}`}
            icon={faCalendarAlt}
          />
        </div>
      </div>
    );
  };
  
  export default DateStrap;
  
