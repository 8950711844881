import { useState, useEffect } from 'react';
import { isMobile, isTablet, isDesktop } from 'react-device-detect';

const UseDeviceDetect = () => {
  const [deviceType, setDeviceType] = useState('');
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1185);
  const [isLandscapeView, setIsLandscapeView] = useState(window.innerWidth > window.innerHeight && window.innerHeight <= 414);

  useEffect(() => {
    const detectDeviceType = () => {
      if (isMobile) setDeviceType('Mobile');
      else if (isTablet) setDeviceType('Tablet');
      else if (isDesktop) setDeviceType('Desktop');
    };

    detectDeviceType();
    window.addEventListener('resize', () => {
      setIsMobileView(window.innerWidth <= 1185);
      setIsLandscapeView(window.innerWidth > window.innerHeight && window.innerHeight <= 431);
      detectDeviceType();
    });

    return () => window.removeEventListener('resize', detectDeviceType);
  }, []);

  return { isMobileView, isLandscapeView, deviceType};
};

export default UseDeviceDetect;
