// FeedbackDialog.js
import React, { useState, useEffect } from 'react';

const FeedbackDialog = ({ showFeedbackDialog, handleFeedbackClose, apiFeedbackUrl, apiAuthKey }) => {
  const [feedbackComment, setFeedbackComment] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [feedbackResponseSubmitted, setFeedbackResponseSubmitted] = useState(false);

  const sanitizeInput = (input) => {
    return input.replace(/[^a-zA-Z0-9\s]/g, '');
  };

  useEffect(() => {
    if (showFeedbackDialog || feedbackResponseSubmitted) {
      setUsername('');
      setFeedbackComment('');
      setEmail('');
    }
  }, [showFeedbackDialog]);

  const handleFeedbackSubmit = async (event) => {
    event.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!feedbackComment) {
      alert('Please enter a comment.');
      return;
    }

    if (!username) {
      alert('Please enter your name.');
      return;
    }

    if (email && !emailRegex.test(email)) {
      alert('Please enter a valid email address or leave blank.');
      return;
    }

    try {
      const response = await fetch(`${apiFeedbackUrl}`, {
        method: 'POST',
        headers: {
          'Authorization': `${apiAuthKey}`
        },
        body: JSON.stringify({
          "reportDate": new Date().toISOString(),
          "username": sanitizeInput(username),
          "email": email,
          "url": window.location.href,
          "comment": sanitizeInput(feedbackComment)
        })
      });
      setFeedbackResponseSubmitted(true);
      setTimeout(handleFeedbackClose, 4000);
      setTimeout(() => {
        setFeedbackResponseSubmitted(false);
       }, 4000);
      
    } catch (error) {
      console.error('Error posting feedback:', error);
    }
  };

  return (
    showFeedbackDialog && (
      <div className="dialog-left-overlay">
        <div className="dialog">
          <button className="close-btn" onClick={handleFeedbackClose}>X</button>
          {!feedbackResponseSubmitted ? (
            <form onSubmit={handleFeedbackSubmit}>
              <p className="prompt-text">Found a mistake? Any ideas on making it better? Feedback is welcome here.</p>
              <textarea
                value={feedbackComment}
                onChange={(e) => setFeedbackComment(e.target.value)}
                placeholder="Enter your comment (max 255 chars)"
                maxLength="255"
              />
              <input
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter your name (max 50 chars)"
                maxLength="50"
              />
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email (optional)"
              />
              <button type="submit">Submit</button>
            </form>
          ) : (
            <p>Thank you for your feedback!</p>
          )}
        </div>
      </div>
    )
  );
};

export default FeedbackDialog;
