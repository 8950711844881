// calcMoment.js

import { format, addDays, subDays, addWeeks, subWeeks, addMonths, subMonths, addYears, subYears } from 'date-fns';

function CalcMoment ( newDate, specialMoment, lowerDateBound, upperDateBound ) {
    //console.log('newDate: ' + newDate);
    let year = newDate.getFullYear();
    switch (specialMoment) {
        case "easter":
        case "whitMonday":
            // magic formula to find easter
            const a = year % 19;
            const b = Math.floor(year / 100);
            const c = year % 100;
            const d = Math.floor(b / 4);
            const e = b % 4;
            const f = Math.floor((b + 8) / 25);
            const g = Math.floor((b - f + 1) / 3);
            const h = (19 * a + b - d - g + 15) % 30;
            const i = Math.floor(c / 4);
            const k = c % 4;
            const l = (32 + 2 * e + 2 * i - h - k) % 7;
            const m = Math.floor((a + 11 * h + 22 * l) / 451);
            const month = Math.floor((h + l - 7 * m + 114) / 31);
            const day = ((h + l - 7 * m + 114) % 31) + 2; // was + 1
            newDate = specialMoment === "easter" ? new Date(year, month - 1, day) : new Date(year, month - 1, day + 50);
            break;
        case "xmas":
            newDate = new Date(newDate.getFullYear(), 11, 25, 18, 0, 0); // December is month 11 (0-indexed)
            break;
        case "add6wks":
            newDate = new Date(newDate.setDate(newDate.getDate() + (6 * 7))); 
            break;
        case "add6mns":
            newDate = new Date(newDate.getFullYear(), newDate.getMonth() + 6, newDate.getDay(), 18, 0, 0); 
            break;
        case "halloween":
            newDate = new Date(newDate.getFullYear(), 9, 31, 18, 0, 0); // October is month 9 (0-indexed)
            break;
        case "newYear":
            newDate = new Date(newDate.getFullYear(), 0, 1, 18, 0, 0); // Jan is month 0 (0-indexed)
            break;
        case "newYearsEve":
            newDate = new Date(newDate.getFullYear(), 11, 31, 18, 0, 0); // Dec is month 11 (0-indexed)
            break;
        case "today":
            const currentYear = newDate.getFullYear();
            const now = new Date();
            // Create a new date using the year from currentDate and month/day from the dateToModify
            newDate = new Date(currentYear, now.getMonth(), now.getDate(), 18, 0, 0);
            break;
        case "autumn":
        case "autumn6":

            // Create a date object for September 1st of the given year
            const september1st = new Date(year, 8, 1, 18, 0, 0);

            //console.log('sep the first: ' + september1st);
            // Get the day of the week for September 1st (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
            const dayOfWeek = september1st.getDay();

            // Calculate the number of days needed to reach the first Monday in September
            // If September 1st is already a Monday, we don't need to add any days (dayOfWeek = 1)
            // Otherwise, we need to add the number of days remaining until the next Monday (7 - dayOfWeek)
            const daysToAdd = dayOfWeek === 1 ? 0 : 7 - dayOfWeek;

            // Calculate the date of the first Monday in September/6 weeks later
            newDate = specialMoment === "autumn" ? new Date(year, 8, 2 + daysToAdd, 18, 0, 0) : new Date(year, 8, 44 + daysToAdd, 18, 0, 0); 
            break;
        case "mayDay":

            // Create a date object for May 1st of the given year
            const startOfMonth = new Date(year, 4, 1);

            //console.log('may the first: ' + startOfMonth);
            // Get the day of the week for May 1st (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
            const dayOfWeek2 = startOfMonth.getDay();

            // Calculate the number of days needed to reach the first Monday
            // If already a Monday, we don't need to add any days (dayOfWeek = 1)
            // Otherwise, we need to add the number of days remaining until the next Monday (7 - dayOfWeek)
            const daysToAdd2 = dayOfWeek2 === 1 ? 0 : 7 - dayOfWeek2;

            // Calculate the date of the first Monday in May
            newDate = new Date(year, 4, 2 + daysToAdd2, 18, 0, 0);
            break;
        case "random":

            const start = new Date(lowerDateBound);
            const end = new Date(upperDateBound); 
            
            // Generate a random timestamp between start and end
            const randomTimestamp = start.getTime() + Math.random() * (end.getTime() - start.getTime());
            const randomDate = new Date(randomTimestamp);
        
            newDate = new Date(randomDate.getFullYear(), randomDate.getMonth(), randomDate.getDay(), 18, 0, 0); ;
            break;    
        default:
            break;
    }
    return newDate;
}

export default CalcMoment;