// ImageDisplay.js
import React, { useRef, useState, useEffect, useCallback  } from 'react';
import { useParams, useNavigate, useSearchParams  } from 'react-router-dom';
import { addDays, subDays, addWeeks, subWeeks, addMonths, subMonths, addYears, subYears } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';

import './css/controls.css';
import './css/dialog.css';
import './css/dateStrap.css';

import FeedbackDialog from './components/FeedbackDialog';
import DebugDisplay from './components/DebugDisplay';
import AboutDialog from './components/AboutDialog';
import Alert from './components/Alert';
import Clipboard from './components/Clipboard';
import DateStrap from './components/DateStrap';
import CalendarDialog from './components/Calendar';
import CalcMoment from './components/CalcMoment';
import ImageTransform from './components/TransformComponent';
import { renderDateControls, renderSpecialControls } from './components/Controls';
import useDeviceDetect from './components/UseDeviceDetect';


import config from './config/config.json';

const ImageDisplay = () => {
  // read from env.local or env vars
  const debug = process.env.REACT_APP_DEBUG_MODE;
  const apiAuthKey = process.env.REACT_APP_API_KEY;

  // ref vars
  const imageViewerRef = useRef(null);

  // state vars
  const [imageUrl, setImageUrl] = useState('');
  const [loaded, setLoaded] = useState(false);

  const { isMobileView, isLandscapeView, deviceType } = useDeviceDetect();
  
  // show overlay dialogs
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [showAboutDialog, setShowAboutDialog] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  // read from config
  const [showNew, setShowNew] = useState(config.isNewVisible);

  const [copied, setCopied] = useState(false);

  // feedback state
  const [feedbackResponseSubmitted, setFeedbackResponseSubmitted] = useState(false);

  const [menuVisible, setMenuVisible] = useState(false);
  const [dayMenuVisible, setDayMenuVisible] = useState(true);
  const [calendarVisible, setCalendarVisible] = useState(false);

  const [smallDateStrap, setSmallDateStrap] = useState(window.innerWidth <= 1185);
  
  // Function to check if the date is in the format YYYY-MM-DD  
  function isValidDate (dateString, config) {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date.getTime()) &&
      date >= new Date(config.lowerDateBound) && date <= new Date(config.upperDateBound);
  };

   useEffect(() => {
    const handleResize = () => {
      setSmallDateStrap(window.innerWidth <= 1185);
      setCalendarVisible(false);
    };

    window.addEventListener("resize", handleResize);
    
    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

const handleEvent = async (action) => {
if (! window.location.href.includes("localhost") && config.enableEvents) { 
  try {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const response = await fetch(`${config.apiEventUrl}`, {
      method: 'POST',
      headers: {
        'Authorization': `${apiAuthKey}`
      },
      body: JSON.stringify({ // Convert object to JSON string
        "action": action,
        "viewport": `${width}x${height}`,
        "deviceType": `${deviceType}`,
        "url": window.location.href
      })
      });
    } catch (error) {
      console.log(`Error posting event`, error);
    }
    }
  };

  const { userDate: paramDate } = useParams();
  //console.log(userDate);
  const [searchParams] = useSearchParams();
  const queryDate = searchParams.get('date');
  if (queryDate) { handleEvent(`Visit by shared link`);}
  // Prefer the query parameter if available, otherwise fallback to the URL parameter
  let inputDate = queryDate || paramDate || config.defaultDate;



  // Check if the incoming inputDate is a valid date
  if (!isValidDate(inputDate, config) || inputDate.length !== 10) {
    inputDate = config.defaultDate;
  }

  const [selectedDate, setSelectedDate] = useState(inputDate);
  // to change url on button
  const navigate = useNavigate();

  useEffect(() => {
    fetchData(selectedDate);
  }, [selectedDate]);

  /*   useEffect(() => {
    if (imageViewerRef.current) {
      imageViewerRef.current.resetTransform();  // Call resetTransform in the child
    }
  }, [imageUrl]); */

  // api call for issueUrl
  const fetchData = async (date) => {
    setLoaded(false);
    setImageUrl('');
    document.title = `UKArchiveTV: ${selectedDate}`;
    try {
      const response = await fetch(`${config.apiUrl}${date}`, {
        method: 'GET',
        headers: {
          'Authorization': `${apiAuthKey}`,
        },
      });
      const data = await response.json();
      const newImageUrl = data.issueUrl ? (data.issueUrl === "missing" ? `${config.missingUrl}` : data.issueUrl) : `${config.missingUrl}`;
      setImageUrl(newImageUrl);
      navigate(`/${date}`);
    } catch (error) {
      console.error('Error fetching picUrl:', error);
    }  
  };

   const handleCopyLink = () => {
    // record event
    handleEvent(`Copy link pressed`);
    navigator.clipboard.writeText(window.location.origin + '/?date=' + selectedDate)
    .then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
       }, 5000);
    })
    .catch((error) => {
      console.error('Failed to copy:', error);
    });
  };

  const handleFeedback = () => {
    handleEvent(`Feedback pressed`);
    handleAboutClose();
    setShowFeedbackDialog(true);
  };

  const handleAbout = () => {
    handleEvent(`About pressed`);
    handleFeedbackClose();
    setShowAboutDialog(true);
  };

  const handleLike = () => {
    handleEvent(`Like pressed`);
  };

  // close dialog and clear fields
  const handleFeedbackClose = () => {
    setShowFeedbackDialog(false);
    setFeedbackResponseSubmitted(false);
  };

  const handleAboutClose = () => {
    setShowAboutDialog(false);
  };

  const getGenomeUrl = (selectedDate) => {
    const date = new Date(selectedDate);
  
    if (date >= new Date("1964-04-18")) {
        return `https://genome.ch.bbc.co.uk/schedules/service_bbc_one_london/${selectedDate}`;
    } else if (date >= new Date("1934-01-08")) {
        return `https://genome.ch.bbc.co.uk/schedules/service_bbc_television_service/${selectedDate}`;
    } else if (date >= new Date("1930-10-12")) {
        return `https://genome.ch.bbc.co.uk/schedules/service_rt_national_programme_london/${selectedDate}`;
    } else if (date >= new Date("1930-03-09")) {
        return `https://genome.ch.bbc.co.uk/schedules/service_rt_national_programme_daventry/${selectedDate}`;
    } else if (date >= new Date("1922-11-14")) {
        return `https://genome.ch.bbc.co.uk/schedules/service_rt_2lo/${selectedDate}`;
    }

    return null; // Handle out-of-bounds dates if needed
};

const handleGenome = () => {
  const genomeUrl = getGenomeUrl(selectedDate);
  if (genomeUrl) {
    const newWindow = window.open(genomeUrl, "_blank", "noopener,noreferrer");
  } else {
      alert("Selected date is out of range.");
  }
};

  const toggleMenu = () => {
    handleEvent(`toggleMenu pressed`);
    setMenuVisible(!menuVisible);
  };

  const toggleDayMenu = () => {
    handleEvent(`toggleDayMenu pressed`);
    setDayMenuVisible(!dayMenuVisible);
  };

  const toggleCalendar = () => {
    handleEvent(`Calendar pressed`);
    setCalendarVisible(!calendarVisible);
  };

  const moveDate = useCallback((direction, period) => {
    setLoaded(false);
    let newDate = new Date(selectedDate + ' 18:00:00');
    switch (period) {
      case "day":
        newDate = direction === 'next' ? addDays(newDate, 1) : subDays(newDate, 1);
        break;
      case "week":
        newDate = direction === 'next' ? addWeeks(newDate, 1) : subWeeks(newDate, 1);
        break;
      case "month":
        newDate = direction === 'next' ? addMonths(newDate, 1) : subMonths(newDate, 1);
        break;
      case "year":
        newDate = direction === 'next' ? addYears(newDate, 1) : subYears(newDate, 1);
        break;
      case "decade":
        newDate = direction === 'next' ? addYears(newDate, 10) : subYears(newDate, 10);
        break;
      default:
        break;
    }

    if (!(newDate >= new Date(config.lowerDateBound) && newDate <= new Date(config.upperDateBound))) {
      setShowAlert(true);
      setLoaded(true);      
      setTimeout(() => {
        setShowAlert(false);
       }, 5000);
    } else {
      setSelectedDate(newDate.toISOString().split('T')[0]);
/*       if (imageViewerRef.current) {
        imageViewerRef.current.resetTransform();  // Call resetTransform in the child
      } */
    }
    setMenuVisible(false);
  }, [selectedDate]);

  const moveDateSpecial = useCallback((newDate, specialMoment) => {
    handleEvent(`${specialMoment} pressed`);
    newDate = CalcMoment(new Date(selectedDate + ' 18:00:00'), specialMoment, config.lowerDateBound, config.upperDateBound);
    if (!(newDate >= new Date(config.lowerDateBound) && newDate <= new Date(config.upperDateBound))) {
      setShowAlert(true);
      setLoaded(true);      
      setTimeout(() => {
        setShowAlert(false);
       }, 5000);
    } else {
      setSelectedDate(newDate.toISOString().split('T')[0]);
    }
    setMenuVisible(false);
  }, [selectedDate]);

  const timer = setTimeout(() => {
    setShowNew(false);
  }, 7000); 

  /*console.log(`smallDateStrap ${smallDateStrap}`);  
  console.log(`deviceType ${deviceType}`);
  console.log(`isMobileView ${isMobileView}`);
  console.log(`isLandscapeView ${isLandscapeView}`);*/

  return (   
    <div className={`base ${isLandscapeView ? 'landscape' : 'portrait'}`}>

    <div className="header">
    {isMobileView && (
      <div className={`controls-menu ${menuVisible ? 'show-menu' : ''}`}>
      {/* Special controls */}
      <div className="special-controls-wrapper mobile-grid">
        {renderSpecialControls(moveDateSpecial, selectedDate, isMobileView, handleAbout, handleFeedback, handleGenome)}
      </div>
    </div>
    )}    
    <DateStrap 
      selectedDate={selectedDate}
      handleAbout={handleAbout}
      menuVisible={menuVisible}
      toggleMenu={toggleMenu}
      smallDateStrap={smallDateStrap}
      isMobileView={isMobileView}
      showNew={showNew}
      toggleCalendar={toggleCalendar}
      calendarVisible={calendarVisible}
      handleCopyLink={handleCopyLink}
      handleFeedback={handleFeedback}
      handleGenome={handleGenome}
    /> 

    <CalendarDialog
        calendarVisible={calendarVisible}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        lowerDateBound={config.lowerDateBound}
        upperDateBound={config.upperDateBound}
    />

    { debug === "true" ? 
    <DebugDisplay 
        config={config} 
        selectedDate={selectedDate} 
        imageUrl={imageUrl} 
        loaded={loaded} 
        showAlert={showAlert} 
    /> : null}


  <div className="button-toolbar">
    <div className={`controls-wrapper row ${dayMenuVisible && isLandscapeView ? 'show-menu' : ''}`}>
      {renderDateControls(moveDate, 'Decade', 'decade')}
      {renderDateControls(moveDate, 'Year', 'year')}
      {renderDateControls(moveDate, 'Month', 'month')}
      {renderDateControls(moveDate, 'Week', 'week')}
      {renderDateControls(moveDate, 'Day', 'day')}
      {isLandscapeView && (
        <button className="day-menu-button" onClick={toggleDayMenu}>
        <FontAwesomeIcon
          icon={faCaretRight}
        />
      </button>
      )}
    </div>
    {!isMobileView && (
    <div className={`special-controls-wrapper desktop-grid`}>
        {renderSpecialControls(moveDateSpecial, selectedDate, isMobileView, handleAbout, handleFeedback, handleGenome)}    
    </div>
    )}
    {!dayMenuVisible && isLandscapeView && (
      <button className="day-menu-button" onClick={toggleDayMenu}>
      <FontAwesomeIcon
        icon={faCaretLeft}
      />
      </button>
    )}
    </div>

    <FeedbackDialog 
      showFeedbackDialog={showFeedbackDialog} 
      handleFeedbackClose={handleFeedbackClose}
      feedbackResponseSubmitted={feedbackResponseSubmitted}
      apiFeedbackUrl={config.apiFeedbackUrl}
      apiAuthKey={apiAuthKey}
    />

    <AboutDialog 
      showAboutDialog={showAboutDialog} 
      handleAboutClose={handleAboutClose}
      aboutUrl={config.aboutUrl}
    />

    <Alert showMessage={showAlert} />

    <Clipboard showMessage={copied} />
    </div>
    <div id="image-container">
    <ImageTransform ref={imageViewerRef} 
      imageUrl={imageUrl} 
      loaded={loaded}
      loadingFilename={config.loadingFilename}
      setLoaded={setLoaded}
      selectedDate={selectedDate}
      handleEvent={handleEvent}
      minScale={config.minScale}
      maxScale={config.maxScale}
      scaleStep={config.scaleStep}
    />
    </div>
  </div>
  );
};

export default ImageDisplay;
