import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';

export const renderDateControls  = ( moveDate, label, period ) => {
  return (
      <div className="button-container">
      <span className="time-period-label">{label}</span>
      <div className="button-container-row">
        <button type="button" className="control-button arrow" onClick={() => moveDate("previous", period)}>
          <FontAwesomeIcon
            icon={faCaretLeft}
          />
        </button>
        <button type="button" className="control-button arrow" onClick={() => moveDate("next", period)}>
        <FontAwesomeIcon
            icon={faCaretRight}
        />
        </button>

      </div>
    </div>
  )
};
  
export const renderSpecialControls = ( moveDateSpecial, selectedDate, isMobileView, handleAbout, handleFeedback, handleGenome ) => {
  return (
  <>
      {isMobileView ? (
          <>
              <button type="button" className="control-button" onClick={handleAbout}>About</button>
              <button type="button" className="control-button" onClick={handleFeedback}>Feedback</button>
              <button type="button" className="control-button" onClick={handleGenome}>Genome</button>
              <hr />
          </>
      ) : null}

        <button type="button" className="control-button" onClick={() => moveDateSpecial(selectedDate, "xmas")}>Xmas</button>
        <button type="button" title="Gauss's Easter Algorithm" className="control-button" onClick={() => moveDateSpecial(selectedDate, "easter")}>Easter</button>
        {//<button type="button" className="control-button" onClick={() => moveDateSpecial(selectedDate, "whitMonday")}>Whit Monday</button> --> 
        }
        <button type="button" title="First Monday in May" className="control-button" onClick={() => moveDateSpecial(selectedDate, "mayDay")}>May Day</button>
        <button type="button" title="First Monday in September" className="control-button" onClick={() => moveDateSpecial(selectedDate, "autumn")}>Autumn</button>
        <button type="button" title="Six weeks from First Monday in September" className="control-button" onClick={() => moveDateSpecial(selectedDate, "autumn6")}>Autumn+6</button>
        <button type="button" title="Wooo" className="control-button" onClick={() => moveDateSpecial(selectedDate, "halloween")}>Halloween</button>
        <button type="button" className="control-button" onClick={() => moveDateSpecial(selectedDate, "newYear")}>New Year</button>
        <button type="button" className="control-button" onClick={() => moveDateSpecial(selectedDate, "newYearsEve")}>NYE</button>
        <button type="button" title="Today in the selected year" className="control-button" onClick={() => moveDateSpecial(selectedDate, "today")}>Today</button>
        <button type="button" className="control-button" onClick={() => moveDateSpecial(selectedDate, "add6wks")}>+6w</button>
        <button type="button" className="control-button" onClick={() => moveDateSpecial(selectedDate, "add6mns")}>+6m</button>
        <button type="button" title="Pick a random day" className="control-button" onClick={() => moveDateSpecial(selectedDate, "random")}>Random</button>
      </>
  )
};


