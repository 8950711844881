// Calendar.js
import React from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CalendarDialog = ({ calendarVisible, selectedDate, setSelectedDate, lowerDateBound, upperDateBound }) => {

  return (
    calendarVisible && (
      <div className="datepicker-container">
      <DatePicker
      selected={new Date(selectedDate + ' 18:00:00')}
      startOpen={false}
      shouldCloseOnSelect={false}
      shouldCloseOnClickOutside={true}
      onChange={(date) => setSelectedDate(date.toISOString().split('T')[0])}
      dateFormat="d MMMM yyyy"
      showYearDropdown="true"
      scrollableYearDropdown="true"
      inline={true}
      onBlur={() => calendarVisible === false}
      onClickOutside={() => calendarVisible === false}
      minDate={new Date(lowerDateBound)}
      maxDate={new Date(upperDateBound)}
      />
    </div>
    )
  );
};

export default CalendarDialog;


